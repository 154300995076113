import React from 'react';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';

const partnersInfo = [
  {
    alt: 'partner 1',
    src: '/images/partners/partner_logo_1.png',
    className: 'mt-2',
    width: 192,
  },
  {
    alt: 'partner 2',
    src: '/images/partners/partner_logo_2.png',
    className: 'mt-5',
    width: 192,
  },
  {
    alt: 'partner 3',
    src: '/images/partners/partner_logo_3.png',
    className: 'mt-1',
    width: 192,
  },
  {
    alt: 'partner 4',
    src: '/images/partners/partner_logo_4.png',
    className: 'mt-3',
    width: 222,
  },
  {
    alt: 'partner 5',
    src: '/images/partners/partner_logo_5.png',
    className: 'mt-2',
    width: 222,
  },
];

const Partners = () => {
  return (
    <div>
      <div className='dark:bg-jacarta-800 bg-light-base'>
        <div className='container'>
          <Swiper
            breakpoints={{
              100: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              575: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              992: {
                slidesPerView: 5,
              },
            }}
            grabCursor={false}
            centeredSlides={true}
            slidesPerView={5}
            spaceBetween={2}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className='swiper !py-5'
          >
            {partnersInfo.map((item) => {
              return (
                <SwiperSlide key={item.alt}>
                  <Link href='#'>
                    <a>
                      <img
                        src={item.src}
                        alt={item.alt}
                        className={item.className}
                        width={item.width}
                      />
                    </a>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Partners;
